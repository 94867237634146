import css from "./HgwSlider.module.scss"
import React, { useEffect, useState } from "react"

const HgwSlider = prop => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const children = prop.children
  const totalSlide = children.length

  const next = () => {
    let counter = currentIndex
    counter += 1
    if (counter >= totalSlide) {
      counter = 0
    }
    // console.log(`currentIndex is ${currentIndex}`)
    setCurrentIndex(counter)
  }

  let sliderIntervalId = setTimeout(() => {
    next()
  }, 5000)

  useEffect(() => {
    return () => {
      // console.log(`timer has deleted`)
      clearTimeout(sliderIntervalId)
    }
  })

  let content = []
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i]
    const cssCurrent = i === currentIndex ? css.current : ""
    const cssType = prop.type ? css[prop.type] : css.type1
    const childWrap = (
      <div key={i} className={css.item + ` ` + cssType + ` ` + cssCurrent}>
        {child}
      </div>
    )
    content.push(childWrap)
  }

  return <div className={css.base}>{content}</div>
}

export default HgwSlider
