import React from "react"
import GlobalNavi from "../../components/GlobalNavi"
import { graphql, Link } from "gatsby"
import DocLayout from "../../components/DocLayout"
import { Box } from "@material-ui/core"
import LinkButton from "../../components/LinkButton"
import SEO from "../../components/seo"
import HgwSlider from "../../components/HgwSlider"
import css from "../../components/TopSlider.module.scss"

export default function Home(arg) {
  const data = arg.data

  return (
    <div>
      <SEO title={`プロジェクトブックのご案内`} />
      <GlobalNavi />
      <div className="l-wrapper">
        <nav aria-label="パンくずリスト">
          <ul className={`breadcrumb`}>
            <li>
              <Link to={`/`}>Home</Link>
            </li>
            <li>
              <Link to={`/profile`}>プロジェクトブックのご案内</Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className={`l-wrapper`}>
        <div className={`l-wrapper__inner`}>
          <DocLayout>
            <Box
              mt={{ xs: `30px`, sm: `60px` }}
              mb={{ xs: `20px`, sm: `40px` }}
            >
              <h2>ブック・ダウンロード</h2>
            </Box>
            <div>
              <p className={`fontLvBody`}>
                ブック『そのうち届くラブレター』はこちらからダウンロードできます。
              </p>
            </div>
            <Box
              mt={{ xs: `30px`, sm: `60px` }}
              mb={{ xs: `20px`, sm: `40px` }}
            >
              <HgwSlider>
                {data.allFile.edges.map((image, key) => {
                  return (
                    <div key={key} className={css.slideCustom}>
                      <img
                        src={image.node.childImageSharp.fixed.src}
                        alt={""}
                      />
                    </div>
                  )
                })}
              </HgwSlider>
            </Box>
          </DocLayout>
          <LinkButton
            color={`green`}
            to={"/assets/data/yokopara2020_book.pdf"}
            target={`_blank`}
          >
            ブックデータのダウンロード
          </LinkButton>
          <p className={`fontLvBody`}>
            また、11月18日（水）〜24日（火）のヨコハマ・パラトリエンナーレ
            のコア期間中、横浜市庁舎にて印刷版を無料配布します（先着順。無くなり次第終了させていただきます）。
          </p>

          <Box>
            <p className={`fontLvBody`}>
              下記のリンクより、郵送も受け付けております。
              （本体無料。送料￥500のみご負担いただきます。こちらも現物が無くなり次第終了させていただきます）
            </p>
            <LinkButton
              color={`green`}
              to={"https://slowlabel.stores.jp/items/5fad36508a45721542a51e49"}
              target={`_blank`}
            >
              ブック販売サイトへ
            </LinkButton>
          </Box>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    allFile(
      filter: { relativePath: { regex: "/bookproject\\\\/yokopara/" } }
      sort: { order: ASC, fields: relativePath }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 980) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`
